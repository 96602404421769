table.resizable thead,
table.resizable tbody,
table.resizable tr {
  display: contents;
}

table.resizable th {
  text-align: left;
  padding: 16px 20px;
}

table.resizable th p,
table.resizable td p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
